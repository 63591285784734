import React, { useEffect, useState, useRef, useCallback } from "react";
import ProductBreadcrumb from "components/ProductBreadcrumb/ProductBreadcrumb";
import { Card, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { convertCharacter } from "services/convertCharacter";
import { ArticleCategoryInterface } from "types/articleCategory";

export interface ArticleCategoryListItemProps {
  category: ArticleCategoryInterface;
}

export function ArticleCategoryListItem({
  category,
}: ArticleCategoryListItemProps) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const divRefs = useRef<(HTMLAnchorElement | null)[]>([]);

  const handleArrowNavigation = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setActiveIndex((prevIndex) => Math.max(prevIndex - 1, -1));
      } else if (e.key === "ArrowLeft") {
        e.preventDefault();
        setActiveIndex((prevIndex) => Math.max(prevIndex - 1, -1));
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setActiveIndex((prevIndex) =>
          Math.min(prevIndex + 1, (category.groups?.length || 0) - 1)
        );
      } else if (e.key === "ArrowRight") {
        e.preventDefault();
        setActiveIndex((prevIndex) =>
          Math.min(prevIndex + 1, (category.groups?.length || 0) - 1)
        );
      }
    },
    [category.groups]
  );

  useEffect(() => {
    if (divRefs.current.length > 0 && activeIndex !== -1) {
      divRefs.current[activeIndex]?.focus();
    }
  }, [activeIndex]);

  useEffect(() => {
    window.addEventListener("keydown", handleArrowNavigation);
    return () => {
      window.removeEventListener("keydown", handleArrowNavigation);
    };
  }, [handleArrowNavigation]);

  return (
    <>
      <ProductBreadcrumb />
      <Card style={{ border: "none" }}>
        <Card.Header
          style={{
            border: "none",
            backgroundColor: "#fff",
          }}
        >
          <h1 className="d-none d-xl-block">{category.names[0].name}</h1>
          <h2 className="d-xl-none">{category.names[0].name}</h2>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md="8">
              <p
                style={{
                  verticalAlign: "top",
                }}
              >
                {convertCharacter(category.names[0].description)}
              </p>

              <Row>
                {category.groups?.map((ci, index) => (
                  <Col md={4} xs={6} data-aos="fade-up" key={ci.id}>
                    <Link
                      to={`/group/${ci?.id}`}
                      tabIndex={index === activeIndex ? 0 : -1}
                      ref={(el: HTMLAnchorElement) =>
                        (divRefs.current[index] = el)
                      }
                    >
                      <Card
                        style={{
                          height: "100%",
                          border: "none",
                        }}
                        className={`text-black transform-hover ${
                          index === activeIndex ? "selected-item" : ""
                        } `}
                      >
                        <Card.Header
                          style={{
                            backgroundColor: "#fff",
                            padding: 0,
                          }}
                          className="group-effect"
                        >
                          <hr />
                          <h3>{ci.names[0].name}</h3>
                        </Card.Header>
                        <Card.Img
                          src={ci.image?.src}
                          variant="top"
                          style={{
                            aspectRatio: 4 / 5,
                          }}
                        />
                      </Card>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col md="4">
              <Image
                src={category?.image.src}
                style={{
                  aspectRatio: "1 / 1",
                  objectFit: "contain",
                }}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row></Row>
    </>
  );
}
