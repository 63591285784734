import React, { useEffect, useState, useRef, useCallback } from "react";
import { Card, Col, Image, Row, Spinner } from "react-bootstrap";
import { ArticleGroup } from "types/articleGroup";
import { convertCharacter } from "services/convertCharacter";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import ProductBreadcrumb from "components/ProductBreadcrumb/ProductBreadcrumb";
import { useTranslation } from "react-i18next";

export interface GroupsListItemProps {
  group: ArticleGroup;
}

export function GroupsListItem({ group }: GroupsListItemProps) {
  const { t } = useTranslation();
  let colVal: number = 3;
  const [activeIndex, setActiveIndex] = useState(-1);
  const divRefs = useRef<(HTMLAnchorElement | null)[]>([]);

  const handleArrowNavigation = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setActiveIndex((prevIndex) => Math.max(prevIndex - 1, -1));
      } else if (e.key === "ArrowLeft") {
        e.preventDefault();
        setActiveIndex((prevIndex) => Math.max(prevIndex - 1, -1));
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setActiveIndex((prevIndex) =>
          Math.min(
            prevIndex + 1,
            (group.children.length > 0
              ? group.children?.length
              : group.articles.length || 0) - 1
          )
        );
      } else if (e.key === "ArrowRight") {
        e.preventDefault();
        setActiveIndex((prevIndex) =>
          Math.min(
            prevIndex + 1,
            (group.children.length > 0
              ? group.children.length
              : group.articles.length || 0) - 1
          )
        );
      }
    },
    [group.children, group.articles]
  );

  useEffect(() => {
    if (divRefs.current.length > 0 && activeIndex !== -1) {
      console.log(activeIndex);
      divRefs.current[activeIndex]?.focus();
    }
  }, [activeIndex, group]);

  useEffect(() => {
    window.addEventListener("keydown", handleArrowNavigation);
    return () => {
      window.removeEventListener("keydown", handleArrowNavigation);
    };
  }, [handleArrowNavigation]);

  const showChildImages = useMemo(
    () =>
      group?.children?.every((child) => group.image?.id !== child.image?.id) ||
      false,
    [group]
  );

  switch (group?.children.length) {
    case 1:
      colVal = 12;
      break;
    case 2:
      colVal = 6;
      break;
    case 3:
      colVal = 4;
      break;
  }

  return (
    <>
      <ProductBreadcrumb />
      <Card style={{ border: "none" }}>
        {group !== undefined ? (
          <>
            <Card.Header style={{ border: "none", backgroundColor: "#fff" }}>
              <h1 className="d-none d-xl-block">{group.names[0]?.name} </h1>
              <h2 className="d-xl-none">{group?.names[0]?.name} </h2>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="8">
                  {group.names.length > 0 ? (
                    <p
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      {convertCharacter(group.names[0]?.description)}
                    </p>
                  ) : null}
                  {group.children.length > 0 ? (
                    <>
                      {" "}
                      {group.children.length > 0 ? (
                        <Row>
                          {group?.children.map((gr, index) => (
                            <Col
                              xl={3}
                              md={6}
                              xs={6}
                              className="p-4"
                              data-aos="fade-up"
                              key={gr.id}
                            >
                              <Link
                                to={`/group/${gr.id}`}
                                tabIndex={index === activeIndex ? 0 : -1}
                                ref={(el: HTMLAnchorElement) =>
                                  (divRefs.current[index] = el)
                                }
                              >
                                <Card
                                  style={{
                                    height: "100%",
                                    border: "none",
                                  }}
                                  className="text-black transform-hover"
                                >
                                  {group !== undefined ? (
                                    <>
                                      <Card.Header
                                        style={{
                                          backgroundColor: "#fff",
                                          color: "#990000",
                                          padding: 0,
                                          height: "8vh",
                                        }}
                                      >
                                        <h3>{gr.names[0].name}</h3>
                                      </Card.Header>
                                      {/*<Card.Img
                                        className="mt-3"
                                        src={schubkarre3kl}
                                        variant="top"
                                        style={{
                                          aspectRatio: 4 / 5,
                                          objectFit: "cover",
                                        }}
                                      />*/}
                                      {showChildImages && (
                                        <Card.Img
                                          className="mt-3"
                                          src={gr.image?.src}
                                          variant="top"
                                          style={{
                                            aspectRatio: 4 / 5,
                                            objectFit: "cover",
                                          }}
                                        />
                                      )}
                                    </>
                                  ) : null}
                                </Card>
                              </Link>
                            </Col>
                          ))}
                        </Row>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <span>
                        {t("product.articlenumber")} |{" "}
                        {t("product.description")}
                      </span>
                      <br />
                      {group.articles?.map((groupArticle, index) => (
                        <Link
                          color="#990000"
                          to={`/products/` + groupArticle.id}
                          key={groupArticle.id}
                          tabIndex={index === activeIndex ? 0 : -1}
                          ref={(el: HTMLAnchorElement) =>
                            (divRefs.current[index] = el)
                          }
                          style={{
                            display: "block",
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <b>{groupArticle.number} </b>
                          <b className="mx-4">
                            {groupArticle.descriptions[0].title}
                          </b>
                          <br />
                        </Link>
                      ))}
                    </>
                  )}
                </Col>
                <Col md="4">
                  <Image src={group?.image?.src} data-aos="fade-in" />
                </Col>
              </Row>
            </Card.Body>
          </>
        ) : (
          <Spinner animation="border" />
        )}
      </Card>
    </>
  );
}
