import { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import {
  fetchNewPdfDownloads,
  fetchPdfById,
  fetchPdfDownloads,
} from "services/downloads";
import { AttachmentGroups } from "types/downloads";
import { AttachmentGroupList } from "components/Downloads/AttachmentGroupList";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function DownloadsBrochures() {
  const { t } = useTranslation();
  const [pdfDownload, setPdfDownload] = useState<AttachmentGroups[]>([]);
  const fetchAttachments = async () => {
    try {
      const pdfDownloads: AttachmentGroups[] = await fetchPdfDownloads();
      setPdfDownload(pdfDownloads);

    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };


  useEffect(() => {
    fetchAttachments();
  }, []);
  return (
    <main>
      <section className="section-secondary" >

      <Container >
        <>
          <h1 id="brochures" className="text-center d-none d-xl-block">{t("attachments.brochure")}</h1>
          <h2 id="brochures" className="text-center d-xl-none">{t("attachments.brochure")}</h2>
          <hr />
          <b>{pdfDownload[0]?.names[0]?.title}</b>
          <AttachmentGroupList attachmentGroups={pdfDownload} />
        </>

      </Container>
      </section>
    </main>
  );
}